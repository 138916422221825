@font-face {
  font-family: 'font-icons';
  src: url('./fonts/font-icons.eot');
  src: url('./fonts/font-icons.eot') format('embedded-opentype'),
    url('./fonts/font-icons.woff') format('woff'), url('./fonts/font-icons.ttf') format('truetype'),
    url('./fonts/font-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

[class^='icon-'],
[class*=' icon-'] {
  display: inline-block;
  font-family: 'font-icons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'font-icons';
}

.icon-type:before {
  content: '\e600';
}
.icon-box:before {
  content: '\e601';
}
.icon-archive:before {
  content: '\e602';
}
.icon-envelope:before {
  content: '\e603';
}
.icon-email:before {
  content: '\e604';
}
.icon-files:before {
  content: '\e605';
}
.icon-printer2:before {
  content: '\e606';
}
.icon-folder-add:before {
  content: '\e607';
}
.icon-folder-settings:before {
  content: '\e608';
}
.icon-folder-check:before {
  content: '\e609';
}
.icon-wifi-low:before {
  content: '\e60a';
}
.icon-wifi-mid:before {
  content: '\e60b';
}
.icon-wifi-full:before {
  content: '\e60c';
}
.icon-connection-empty:before {
  content: '\e60d';
}
.icon-battery-full:before {
  content: '\e60e';
}
.icon-settings:before {
  content: '\e60f';
}
.icon-arrow-left:before {
  content: '\e610';
}
.icon-arrow-up:before {
  content: '\e611';
}
.icon-arrow-down:before {
  content: '\e612';
}
.icon-arrow-right:before {
  content: '\e613';
}
.icon-reload:before {
  content: '\e614';
}
.icon-download:before {
  content: '\e615';
}
.icon-tag:before {
  content: '\e616';
}
.icon-trashcan:before {
  content: '\e617';
}
.icon-search:before {
  content: '\e618';
}
.icon-zoom-in:before {
  content: '\e619';
}
.icon-zoom-out:before {
  content: '\e61a';
}
.icon-chat:before {
  content: '\e61b';
}
.icon-clock:before {
  content: '\e61c';
}
.icon-printer:before {
  content: '\e61d';
}
.icon-home:before {
  content: '\e61e';
}
.icon-flag:before {
  content: '\e61f';
}
.icon-meter:before {
  content: '\e620';
}
.icon-switch:before {
  content: '\e621';
}
.icon-forbidden:before {
  content: '\e622';
}
.icon-phone-landscape:before {
  content: '\e623';
}
.icon-tablet:before {
  content: '\e624';
}
.icon-tablet-landscape:before {
  content: '\e625';
}
.icon-laptop:before {
  content: '\e626';
}
.icon-camera:before {
  content: '\e627';
}
.icon-microwave-oven:before {
  content: '\e628';
}
.icon-credit-cards:before {
  content: '\e629';
}
.icon-map-marker:before {
  content: '\e62a';
}
.icon-map:before {
  content: '\e62b';
}
.icon-support:before {
  content: '\e62c';
}
.icon-newspaper2:before {
  content: '\e62d';
}
.icon-barbell:before {
  content: '\e62e';
}
.icon-stopwatch:before {
  content: '\e62f';
}
.icon-atom:before {
  content: '\e630';
}
.icon-image:before {
  content: '\e631';
}
.icon-cube:before {
  content: '\e632';
}
.icon-bars:before {
  content: '\e633';
}
.icon-chart:before {
  content: '\e634';
}
.icon-pencil:before {
  content: '\e635';
}
.icon-measure:before {
  content: '\e636';
}
.icon-eyedropper:before {
  content: '\e637';
}
.icon-file-settings:before {
  content: '\e638';
}
.icon-file-add:before {
  content: '\e639';
}
.icon-file:before {
  content: '\e63a';
}
.icon-align-left:before {
  content: '\e63b';
}
.icon-align-right:before {
  content: '\e63c';
}
.icon-align-center:before {
  content: '\e63d';
}
.icon-align-justify:before {
  content: '\e63e';
}
.icon-file-broken:before {
  content: '\e63f';
}
.icon-browser:before {
  content: '\e640';
}
.icon-windows:before {
  content: '\e641';
}
.icon-window:before {
  content: '\e642';
}
.icon-folder:before {
  content: '\e643';
}
.icon-connection-25:before {
  content: '\e644';
}
.icon-connection-50:before {
  content: '\e645';
}
.icon-connection-75:before {
  content: '\e646';
}
.icon-connection-full:before {
  content: '\e647';
}
.icon-list:before {
  content: '\e648';
}
.icon-grid:before {
  content: '\e649';
}
.icon-stack3:before {
  content: '\e64a';
}
.icon-battery-charging:before {
  content: '\e64b';
}
.icon-battery-empty:before {
  content: '\e64c';
}
.icon-battery-25:before {
  content: '\e64d';
}
.icon-battery-50:before {
  content: '\e64e';
}
.icon-battery-75:before {
  content: '\e64f';
}
.icon-refresh:before {
  content: '\e650';
}
.icon-volume:before {
  content: '\e651';
}
.icon-volume-increase:before {
  content: '\e652';
}
.icon-volume-decrease:before {
  content: '\e653';
}
.icon-mute:before {
  content: '\e654';
}
.icon-microphone:before {
  content: '\e655';
}
.icon-microphone-off:before {
  content: '\e656';
}
.icon-book:before {
  content: '\e657';
}
.icon-checkmark:before {
  content: '\e658';
}
.icon-checkbox-checked:before {
  content: '\e659';
}
.icon-checkbox:before {
  content: '\e65a';
}
.icon-paperclip:before {
  content: '\e65b';
}
.icon-chat-1:before {
  content: '\e65c';
}
.icon-chat-2:before {
  content: '\e65d';
}
.icon-chat-3:before {
  content: '\e65e';
}
.icon-comment:before {
  content: '\e65f';
}
.icon-calendar:before {
  content: '\e660';
}
.icon-bookmark:before {
  content: '\e661';
}
.icon-email2:before {
  content: '\e662';
}
.icon-heart:before {
  content: '\e663';
}
.icon-enter:before {
  content: '\e664';
}
.icon-cloud:before {
  content: '\e665';
}
.icon-book2:before {
  content: '\e666';
}
.icon-star:before {
  content: '\e667';
}
.icon-lock:before {
  content: '\e668';
}
.icon-unlocked:before {
  content: '\e669';
}
.icon-unlocked2:before {
  content: '\e66a';
}
.icon-users:before {
  content: '\e66b';
}
.icon-user:before {
  content: '\e66c';
}
.icon-users2:before {
  content: '\e66d';
}
.icon-user2:before {
  content: '\e66e';
}
.icon-bullhorn:before {
  content: '\e66f';
}
.icon-share:before {
  content: '\e670';
}
.icon-screen:before {
  content: '\e671';
}
.icon-phone:before {
  content: '\e672';
}
.icon-phone-portrait:before {
  content: '\e673';
}
.icon-calculator:before {
  content: '\e674';
}
.icon-bag:before {
  content: '\e675';
}
.icon-diamond:before {
  content: '\e676';
}
.icon-drink:before {
  content: '\e677';
}
.icon-shorts:before {
  content: '\e678';
}
.icon-vcard:before {
  content: '\e679';
}
.icon-sun:before {
  content: '\e67a';
}
.icon-bill:before {
  content: '\e67b';
}
.icon-coffee:before {
  content: '\e67c';
}
.icon-tv2:before {
  content: '\e67d';
}
.icon-newspaper:before {
  content: '\e67e';
}
.icon-stack:before {
  content: '\e67f';
}
.icon-syringe:before {
  content: '\e680';
}
.icon-health:before {
  content: '\e681';
}
.icon-bolt:before {
  content: '\e682';
}
.icon-pill:before {
  content: '\e683';
}
.icon-bones:before {
  content: '\e684';
}
.icon-lab:before {
  content: '\e685';
}
.icon-clipboard:before {
  content: '\e686';
}
.icon-mug:before {
  content: '\e687';
}
.icon-bucket:before {
  content: '\e688';
}
.icon-select:before {
  content: '\e689';
}
.icon-graph:before {
  content: '\e68a';
}
.icon-crop:before {
  content: '\e68b';
}
.icon-heart2:before {
  content: '\e68c';
}
.icon-cloud2:before {
  content: '\e68d';
}
.icon-star2:before {
  content: '\e68e';
}
.icon-pen:before {
  content: '\e68f';
}
.icon-diamond2:before {
  content: '\e690';
}
.icon-display:before {
  content: '\e691';
}
.icon-paperplane:before {
  content: '\e692';
}
.icon-params:before {
  content: '\e693';
}
.icon-banknote:before {
  content: '\e694';
}
.icon-vynil:before {
  content: '\e695';
}
.icon-truck:before {
  content: '\e696';
}
.icon-world:before {
  content: '\e697';
}
.icon-tv:before {
  content: '\e698';
}
.icon-sound:before {
  content: '\e699';
}
.icon-video:before {
  content: '\e69a';
}
.icon-trash:before {
  content: '\e69b';
}
.icon-user3:before {
  content: '\e69c';
}
.icon-key:before {
  content: '\e69d';
}
.icon-search2:before {
  content: '\e69e';
}
.icon-settings2:before {
  content: '\e69f';
}
.icon-camera2:before {
  content: '\e6a0';
}
.icon-tag2:before {
  content: '\e6a1';
}
.icon-lock2:before {
  content: '\e6a2';
}
.icon-bulb:before {
  content: '\e6a3';
}
.icon-location:before {
  content: '\e6a4';
}
.icon-eye:before {
  content: '\e6a5';
}
.icon-bubble:before {
  content: '\e6a6';
}
.icon-stack2:before {
  content: '\e6a7';
}
.icon-cup:before {
  content: '\e6a8';
}
.icon-phone2:before {
  content: '\e6a9';
}
.icon-news:before {
  content: '\e6aa';
}
.icon-mail:before {
  content: '\e6ab';
}
.icon-like:before {
  content: '\e6ac';
}
.icon-photo:before {
  content: '\e6ad';
}
.icon-note:before {
  content: '\e6ae';
}
.icon-clock2:before {
  content: '\e6af';
}
.icon-data:before {
  content: '\e6b0';
}
.icon-music:before {
  content: '\e6b1';
}
.icon-megaphone:before {
  content: '\e6b2';
}
.icon-study:before {
  content: '\e6b3';
}
.icon-lab2:before {
  content: '\e6b4';
}
.icon-food:before {
  content: '\e6b5';
}
.icon-t-shirt:before {
  content: '\e6b6';
}
.icon-fire:before {
  content: '\e6b7';
}
.icon-clip:before {
  content: '\e6b8';
}
.icon-shop:before {
  content: '\e6b9';
}
.icon-calendar2:before {
  content: '\e6ba';
}
.icon-wallet:before {
  content: '\e6bb';
}
.icon-duckduckgo:before {
  content: '\e830';
}
.icon-lkdto:before {
  content: '\e896';
}
.icon-delicious:before {
  content: '\e832';
}
.icon-paypal:before {
  content: '\e833';
}
.icon-flattr:before {
  content: '\e834';
}
.icon-android:before {
  content: '\e835';
}
.icon-eventful:before {
  content: '\e836';
}
.icon-smashmag:before {
  content: '\e837';
}
.icon-gplus:before {
  content: '\e838';
}
.icon-wikipedia:before {
  content: '\e839';
}
.icon-lanyrd:before {
  content: '\e83a';
}
.icon-calendar-1:before {
  content: '\e83b';
}
.icon-stumbleupon:before {
  content: '\e83c';
}
.icon-bitcoin:before {
  content: '\e83f';
}
.icon-w3c:before {
  content: '\e840';
}
.icon-foursquare:before {
  content: '\e841';
}
.icon-html5:before {
  content: '\e842';
}
.icon-ie:before {
  content: '\e843';
}
.icon-call:before {
  content: '\e844';
}
.icon-grooveshark:before {
  content: '\e845';
}
.icon-ninetyninedesigns:before {
  content: '\e846';
}
.icon-forrst:before {
  content: '\e847';
}
.icon-digg:before {
  content: '\e848';
}
.icon-spotify:before {
  content: '\e849';
}
.icon-reddit:before {
  content: '\e84a';
}
.icon-guest:before {
  content: '\e84b';
}
.icon-blogger:before {
  content: '\e84e';
}
.icon-cc:before {
  content: '\e84f';
}
.icon-dribbble:before {
  content: '\e850';
}
.icon-evernote:before {
  content: '\e851';
}
.icon-flickr:before {
  content: '\e852';
}
.icon-google:before {
  content: '\e853';
}
.icon-viadeo:before {
  content: '\e854';
}
.icon-instapaper:before {
  content: '\e855';
}
.icon-weibo:before {
  content: '\e856';
}
.icon-klout:before {
  content: '\e857';
}
.icon-linkedin:before {
  content: '\e858';
}
.icon-meetup:before {
  content: '\e859';
}
.icon-vk:before {
  content: '\e85a';
}
.icon-rss:before {
  content: '\e85d';
}
.icon-skype:before {
  content: '\e85e';
}
.icon-twitter:before {
  content: '\e85f';
}
.icon-youtube:before {
  content: '\e860';
}
.icon-vimeo:before {
  content: '\e861';
}
.icon-windows2:before {
  content: '\e862';
}
.icon-aim:before {
  content: '\e831';
}
.icon-yahoo:before {
  content: '\e864';
}
.icon-chrome:before {
  content: '\e865';
}
.icon-email3:before {
  content: '\e866';
}
.icon-macstore:before {
  content: '\e867';
}
.icon-myspace:before {
  content: '\e868';
}
.icon-podcast:before {
  content: '\e869';
}
.icon-cloudapp:before {
  content: '\e86c';
}
.icon-dropbox:before {
  content: '\e86d';
}
.icon-ebay:before {
  content: '\e86e';
}
.icon-facebook:before {
  content: '\e86f';
}
.icon-github:before {
  content: '\e870';
}
.icon-github-circled:before {
  content: '\e871';
}
.icon-googleplay:before {
  content: '\e872';
}
.icon-itunes:before {
  content: '\e873';
}
.icon-plurk:before {
  content: '\e874';
}
.icon-songkick:before {
  content: '\e875';
}
.icon-lastfm:before {
  content: '\e876';
}
.icon-gmail:before {
  content: '\e877';
}
.icon-pinboard:before {
  content: '\e878';
}
.icon-soundcloud:before {
  content: '\e87b';
}
.icon-tumblr:before {
  content: '\e87c';
}
.icon-eventasaurus:before {
  content: '\e87d';
}
.icon-wordpress:before {
  content: '\e87e';
}
.icon-yelp:before {
  content: '\e87f';
}
.icon-intensedebate:before {
  content: '\e880';
}
.icon-eventbrite:before {
  content: '\e881';
}
.icon-scribd:before {
  content: '\e882';
}
.icon-posterous:before {
  content: '\e883';
}
.icon-stripe:before {
  content: '\e884';
}
.icon-opentable:before {
  content: '\e885';
}
.icon-cart:before {
  content: '\e886';
}
.icon-print:before {
  content: '\e887';
}
.icon-dwolla:before {
  content: '\e88a';
}
.icon-appnet:before {
  content: '\e88b';
}
.icon-statusnet:before {
  content: '\e88c';
}
.icon-acrobat:before {
  content: '\e88d';
}
.icon-drupal:before {
  content: '\e88e';
}
.icon-buffer:before {
  content: '\e88f';
}
.icon-pocket:before {
  content: '\e890';
}
.icon-bitbucket:before {
  content: '\e891';
}
.icon-lego:before {
  content: '\e892';
}
.icon-login:before {
  content: '\e893';
}
.icon-stackoverflow:before {
  content: '\e894';
}
.icon-hackernews:before {
  content: '\e895';
}
.icon-xing:before {
  content: '\e863';
}
.icon-instagram:before {
  content: '\e889';
}
.icon-angellist:before {
  content: '\e888';
}
.icon-quora:before {
  content: '\e87a';
}
.icon-openid:before {
  content: '\e879';
}
.icon-steam:before {
  content: '\e86b';
}
.icon-amazon:before {
  content: '\e86a';
}
.icon-disqus:before {
  content: '\e85c';
}
.icon-plancast:before {
  content: '\e85b';
}
.icon-appstore:before {
  content: '\e84d';
}
.icon-gowalla:before {
  content: '\e84c';
}
.icon-pinterest:before {
  content: '\e83e';
}
.icon-fivehundredpx:before {
  content: '\e83d';
}
.icon-glass:before {
  content: '\e6bc';
}
.icon-music2:before {
  content: '\e6bd';
}
.icon-search3:before {
  content: '\e6be';
}
.icon-envelope2:before {
  content: '\e6bf';
}
.icon-heart3:before {
  content: '\e6c0';
}
.icon-star3:before {
  content: '\e6c1';
}
.icon-star-empty:before {
  content: '\e6c2';
}
.icon-user4:before {
  content: '\e6c3';
}
.icon-film:before {
  content: '\e6c4';
}
.icon-th-large:before {
  content: '\e6c5';
}
.icon-th:before {
  content: '\e6c6';
}
.icon-th-list:before {
  content: '\e6c7';
}
.icon-ok:before {
  content: '\e6c8';
}
.icon-remove:before {
  content: '\e6c9';
}
.icon-zoom-in2:before {
  content: '\e6ca';
}
.icon-zoom-out2:before {
  content: '\e6cb';
}
.icon-off:before {
  content: '\e6cc';
}
.icon-signal:before {
  content: '\e6cd';
}
.icon-cog:before {
  content: '\e6ce';
}
.icon-trash2:before {
  content: '\e6cf';
}
.icon-home2:before {
  content: '\e6d0';
}
.icon-file2:before {
  content: '\e6d1';
}
.icon-time:before {
  content: '\e6d2';
}
.icon-road:before {
  content: '\e6d3';
}
.icon-download-alt:before {
  content: '\e6d4';
}
.icon-download2:before {
  content: '\e6d5';
}
.icon-upload:before {
  content: '\e6d6';
}
.icon-inbox:before {
  content: '\e6d7';
}
.icon-play-circle:before {
  content: '\e6d8';
}
.icon-repeat:before {
  content: '\e6d9';
}
.icon-refresh2:before {
  content: '\e6da';
}
.icon-list-alt:before {
  content: '\e6db';
}
.icon-lock3:before {
  content: '\e6dc';
}
.icon-flag2:before {
  content: '\e6dd';
}
.icon-headphones:before {
  content: '\e6de';
}
.icon-volume-off:before {
  content: '\e6df';
}
.icon-volume-down:before {
  content: '\e6e0';
}
.icon-volume-up:before {
  content: '\e6e1';
}
.icon-qrcode:before {
  content: '\e6e2';
}
.icon-barcode:before {
  content: '\e6e3';
}
.icon-tag3:before {
  content: '\e6e4';
}
.icon-tags:before {
  content: '\e6e5';
}
.icon-book3:before {
  content: '\e6e6';
}
.icon-bookmark2:before {
  content: '\e6e7';
}
.icon-print2:before {
  content: '\e6e8';
}
.icon-camera3:before {
  content: '\e6e9';
}
.icon-font:before {
  content: '\e6ea';
}
.icon-bold:before {
  content: '\e6eb';
}
.icon-italic:before {
  content: '\e6ec';
}
.icon-text-height:before {
  content: '\e6ed';
}
.icon-text-width:before {
  content: '\e6ee';
}
.icon-align-left2:before {
  content: '\e6ef';
}
.icon-align-center2:before {
  content: '\e6f0';
}
.icon-align-right2:before {
  content: '\e6f1';
}
.icon-align-justify2:before {
  content: '\e6f2';
}
.icon-list2:before {
  content: '\e6f3';
}
.icon-indent-left:before {
  content: '\e6f4';
}
.icon-indent-right:before {
  content: '\e6f5';
}
.icon-facetime-video:before {
  content: '\e6f6';
}
.icon-picture:before {
  content: '\e6f7';
}
.icon-pencil2:before {
  content: '\e6f8';
}
.icon-map-marker2:before {
  content: '\e6f9';
}
.icon-adjust:before {
  content: '\e6fa';
}
.icon-tint:before {
  content: '\e6fb';
}
.icon-edit:before {
  content: '\e6fc';
}
.icon-share2:before {
  content: '\e6fd';
}
.icon-check:before {
  content: '\e6fe';
}
.icon-move:before {
  content: '\e6ff';
}
.icon-step-backward:before {
  content: '\e700';
}
.icon-fast-backward:before {
  content: '\e701';
}
.icon-backward:before {
  content: '\e702';
}
.icon-play:before {
  content: '\e703';
}
.icon-pause:before {
  content: '\e704';
}
.icon-stop:before {
  content: '\e705';
}
.icon-forward:before {
  content: '\e706';
}
.icon-fast-forward:before {
  content: '\e707';
}
.icon-step-forward:before {
  content: '\e708';
}
.icon-eject:before {
  content: '\e709';
}
.icon-chevron-left:before {
  content: '\e70a';
}
.icon-chevron-right:before {
  content: '\e70b';
}
.icon-plus-sign:before {
  content: '\e70c';
}
.icon-minus-sign:before {
  content: '\e70d';
}
.icon-remove-sign:before {
  content: '\e70e';
}
.icon-ok-sign:before {
  content: '\e70f';
}
.icon-question-sign:before {
  content: '\e710';
}
.icon-info-sign:before {
  content: '\e711';
}
.icon-screenshot:before {
  content: '\e712';
}
.icon-remove-circle:before {
  content: '\e713';
}
.icon-ok-circle:before {
  content: '\e714';
}
.icon-ban-circle:before {
  content: '\e715';
}
.icon-arrow-left2:before {
  content: '\e716';
}
.icon-arrow-right2:before {
  content: '\e717';
}
.icon-arrow-up2:before {
  content: '\e718';
}
.icon-arrow-down2:before {
  content: '\e719';
}
.icon-share-alt:before {
  content: '\e71a';
}
.icon-resize-full:before {
  content: '\e71b';
}
.icon-resize-small:before {
  content: '\e71c';
}
.icon-plus:before {
  content: '\e71d';
}
.icon-minus:before {
  content: '\e71e';
}
.icon-asterisk:before {
  content: '\e71f';
}
.icon-exclamation-sign:before {
  content: '\e720';
}
.icon-gift:before {
  content: '\e721';
}
.icon-leaf:before {
  content: '\e722';
}
.icon-fire2:before {
  content: '\e723';
}
.icon-eye-open:before {
  content: '\e724';
}
.icon-eye-close:before {
  content: '\e725';
}
.icon-warning-sign:before {
  content: '\e726';
}
.icon-plane:before {
  content: '\e727';
}
.icon-calendar3:before {
  content: '\e728';
}
.icon-random:before {
  content: '\e729';
}
.icon-comment2:before {
  content: '\e72a';
}
.icon-magnet:before {
  content: '\e72b';
}
.icon-chevron-up:before {
  content: '\e72c';
}
.icon-chevron-down:before {
  content: '\e72d';
}
.icon-retweet:before {
  content: '\e72e';
}
.icon-shopping-cart:before {
  content: '\e72f';
}
.icon-folder-close:before {
  content: '\e730';
}
.icon-folder-open:before {
  content: '\e731';
}
.icon-resize-vertical:before {
  content: '\e732';
}
.icon-resize-horizontal:before {
  content: '\e733';
}
.icon-bar-chart:before {
  content: '\e734';
}
.icon-twitter-sign:before {
  content: '\e735';
}
.icon-facebook-sign:before {
  content: '\e736';
}
.icon-camera-retro:before {
  content: '\e737';
}
.icon-key2:before {
  content: '\e738';
}
.icon-cogs:before {
  content: '\e739';
}
.icon-comments:before {
  content: '\e73a';
}
.icon-thumbs-up:before {
  content: '\e73b';
}
.icon-thumbs-down:before {
  content: '\e73c';
}
.icon-star-half:before {
  content: '\e73d';
}
.icon-heart-empty:before {
  content: '\e73e';
}
.icon-signout:before {
  content: '\e73f';
}
.icon-linkedin-sign:before {
  content: '\e740';
}
.icon-pushpin:before {
  content: '\e741';
}
.icon-external-link:before {
  content: '\e742';
}
.icon-signin:before {
  content: '\e743';
}
.icon-trophy:before {
  content: '\e744';
}
.icon-github-sign:before {
  content: '\e745';
}
.icon-upload-alt:before {
  content: '\e746';
}
.icon-lemon:before {
  content: '\e747';
}
.icon-phone3:before {
  content: '\e748';
}
.icon-check-empty:before {
  content: '\e749';
}
.icon-bookmark-empty:before {
  content: '\e74a';
}
.icon-phone-sign:before {
  content: '\e74b';
}
.icon-twitter2:before {
  content: '\e74c';
}
.icon-facebook2:before {
  content: '\e74d';
}
.icon-github2:before {
  content: '\e74e';
}
.icon-unlock:before {
  content: '\e74f';
}
.icon-credit:before {
  content: '\e750';
}
.icon-rss2:before {
  content: '\e751';
}
.icon-hdd:before {
  content: '\e752';
}
.icon-bullhorn2:before {
  content: '\e753';
}
.icon-bell:before {
  content: '\e754';
}
.icon-certificate:before {
  content: '\e755';
}
.icon-hand-right:before {
  content: '\e756';
}
.icon-hand-left:before {
  content: '\e757';
}
.icon-hand-up:before {
  content: '\e758';
}
.icon-hand-down:before {
  content: '\e759';
}
.icon-circle-arrow-left:before {
  content: '\e75a';
}
.icon-circle-arrow-right:before {
  content: '\e75b';
}
.icon-circle-arrow-up:before {
  content: '\e75c';
}
.icon-circle-arrow-down:before {
  content: '\e75d';
}
.icon-globe:before {
  content: '\e75e';
}
.icon-wrench:before {
  content: '\e75f';
}
.icon-tasks:before {
  content: '\e760';
}
.icon-filter:before {
  content: '\e761';
}
.icon-briefcase:before {
  content: '\e762';
}
.icon-fullscreen:before {
  content: '\e763';
}
.icon-group:before {
  content: '\e764';
}
.icon-link:before {
  content: '\e765';
}
.icon-cloud3:before {
  content: '\e766';
}
.icon-beaker:before {
  content: '\e767';
}
.icon-cut:before {
  content: '\e768';
}
.icon-copy:before {
  content: '\e769';
}
.icon-paper-clip:before {
  content: '\e76a';
}
.icon-save:before {
  content: '\e76b';
}
.icon-sign-blank:before {
  content: '\e76c';
}
.icon-reorder:before {
  content: '\e76d';
}
.icon-list-ul:before {
  content: '\e76e';
}
.icon-list-ol:before {
  content: '\e76f';
}
.icon-strikethrough:before {
  content: '\e770';
}
.icon-underline:before {
  content: '\e771';
}
.icon-table:before {
  content: '\e772';
}
.icon-magic:before {
  content: '\e773';
}
.icon-truck2:before {
  content: '\e774';
}
.icon-pinterest2:before {
  content: '\e775';
}
.icon-pinterest-sign:before {
  content: '\e776';
}
.icon-google-plus-sign:before {
  content: '\e777';
}
.icon-google-plus:before {
  content: '\e778';
}
.icon-money:before {
  content: '\e779';
}
.icon-caret-down:before {
  content: '\e77a';
}
.icon-caret-up:before {
  content: '\e77b';
}
.icon-caret-left:before {
  content: '\e77c';
}
.icon-caret-right:before {
  content: '\e77d';
}
.icon-columns:before {
  content: '\e77e';
}
.icon-sort:before {
  content: '\e77f';
}
.icon-sort-down:before {
  content: '\e780';
}
.icon-sort-up:before {
  content: '\e781';
}
.icon-envelope-alt:before {
  content: '\e782';
}
.icon-linkedin2:before {
  content: '\e783';
}
.icon-undo:before {
  content: '\e784';
}
.icon-legal:before {
  content: '\e785';
}
.icon-dashboard:before {
  content: '\e786';
}
.icon-comment-alt:before {
  content: '\e787';
}
.icon-comments-alt:before {
  content: '\e788';
}
.icon-bolt2:before {
  content: '\e789';
}
.icon-sitemap:before {
  content: '\e78a';
}
.icon-umbrella:before {
  content: '\e78b';
}
.icon-paste:before {
  content: '\e78c';
}
.icon-lightbulb:before {
  content: '\e78d';
}
.icon-exchange:before {
  content: '\e78e';
}
.icon-cloud-download:before {
  content: '\e78f';
}
.icon-cloud-upload:before {
  content: '\e790';
}
.icon-user-md:before {
  content: '\e791';
}
.icon-stethoscope:before {
  content: '\e792';
}
.icon-suitcase:before {
  content: '\e793';
}
.icon-bell-alt:before {
  content: '\e794';
}
.icon-coffee2:before {
  content: '\e795';
}
.icon-food2:before {
  content: '\e796';
}
.icon-file-alt:before {
  content: '\e797';
}
.icon-building:before {
  content: '\e798';
}
.icon-hospital:before {
  content: '\e799';
}
.icon-ambulance:before {
  content: '\e79a';
}
.icon-medkit:before {
  content: '\e79b';
}
.icon-fighter-jet:before {
  content: '\e79c';
}
.icon-beer:before {
  content: '\e79d';
}
.icon-h-sign:before {
  content: '\e79e';
}
.icon-plus-sign2:before {
  content: '\e79f';
}
.icon-double-angle-left:before {
  content: '\e7a0';
}
.icon-double-angle-right:before {
  content: '\e7a1';
}
.icon-double-angle-up:before {
  content: '\e7a2';
}
.icon-double-angle-down:before {
  content: '\e7a3';
}
.icon-angle-left:before {
  content: '\e7a4';
}
.icon-angle-right:before {
  content: '\e7a5';
}
.icon-angle-up:before {
  content: '\e7a6';
}
.icon-angle-down:before {
  content: '\e7a7';
}
.icon-desktop:before {
  content: '\e7a8';
}
.icon-laptop2:before {
  content: '\e7a9';
}
.icon-tablet2:before {
  content: '\e7aa';
}
.icon-mobile:before {
  content: '\e7ab';
}
.icon-circle-blank:before {
  content: '\e7ac';
}
.icon-quote-left:before {
  content: '\e7ad';
}
.icon-quote-right:before {
  content: '\e7ae';
}
.icon-spinner:before {
  content: '\e7af';
}
.icon-circle:before {
  content: '\e7b0';
}
.icon-reply:before {
  content: '\e7b1';
}
.icon-github-alt:before {
  content: '\e7b2';
}
.icon-folder-close-alt:before {
  content: '\e7b3';
}
.icon-folder-open-alt:before {
  content: '\e7b4';
}
.icon-expand-alt:before {
  content: '\e7b5';
}
.icon-collapse-alt:before {
  content: '\e7b6';
}
.icon-smile:before {
  content: '\e7b7';
}
.icon-frown:before {
  content: '\e7b8';
}
.icon-meh:before {
  content: '\e7b9';
}
.icon-gamepad:before {
  content: '\e7ba';
}
.icon-keyboard:before {
  content: '\e7bb';
}
.icon-flag-alt:before {
  content: '\e7bc';
}
.icon-flag-checkered:before {
  content: '\e7bd';
}
.icon-terminal:before {
  content: '\e7be';
}
.icon-code:before {
  content: '\e7bf';
}
.icon-reply-all:before {
  content: '\e7c0';
}
.icon-star-half-full:before {
  content: '\e7c1';
}
.icon-location-arrow:before {
  content: '\e7c2';
}
.icon-crop2:before {
  content: '\e7c3';
}
.icon-code-fork:before {
  content: '\e7c4';
}
.icon-unlink:before {
  content: '\e7c5';
}
.icon-question:before {
  content: '\e7c6';
}
.icon-info:before {
  content: '\e7c7';
}
.icon-exclamation:before {
  content: '\e7c8';
}
.icon-superscript:before {
  content: '\e7c9';
}
.icon-subscript:before {
  content: '\e7ca';
}
.icon-eraser:before {
  content: '\e7cb';
}
.icon-puzzle:before {
  content: '\e7cc';
}
.icon-microphone2:before {
  content: '\e7cd';
}
.icon-microphone-off2:before {
  content: '\e7ce';
}
.icon-shield:before {
  content: '\e7cf';
}
.icon-calendar-empty:before {
  content: '\e7d0';
}
.icon-fire-extinguisher:before {
  content: '\e7d1';
}
.icon-rocket:before {
  content: '\e7d2';
}
.icon-maxcdn:before {
  content: '\e7d3';
}
.icon-chevron-sign-left:before {
  content: '\e7d4';
}
.icon-chevron-sign-right:before {
  content: '\e7d5';
}
.icon-chevron-sign-up:before {
  content: '\e7d6';
}
.icon-chevron-sign-down:before {
  content: '\e7d7';
}
.icon-html52:before {
  content: '\e7d8';
}
.icon-css3:before {
  content: '\e7d9';
}
.icon-anchor:before {
  content: '\e7da';
}
.icon-unlock-alt:before {
  content: '\e7db';
}
.icon-bullseye:before {
  content: '\e7dc';
}
.icon-ellipsis-horizontal:before {
  content: '\e7dd';
}
.icon-ellipsis-vertical:before {
  content: '\e7de';
}
.icon-rss-sign:before {
  content: '\e7df';
}
.icon-play-sign:before {
  content: '\e7e0';
}
.icon-ticket:before {
  content: '\e7e1';
}
.icon-minus-sign-alt:before {
  content: '\e7e2';
}
.icon-check-minus:before {
  content: '\e7e3';
}
.icon-level-up:before {
  content: '\e7e4';
}
.icon-level-down:before {
  content: '\e7e5';
}
.icon-check-sign:before {
  content: '\e7e6';
}
.icon-edit-sign:before {
  content: '\e7e7';
}
.icon-external-link-sign:before {
  content: '\e7e8';
}
.icon-share-sign:before {
  content: '\e7e9';
}
.icon-compass:before {
  content: '\e7ea';
}
.icon-collapse:before {
  content: '\e7eb';
}
.icon-collapse-top:before {
  content: '\e7ec';
}
.icon-expand:before {
  content: '\e7ed';
}
.icon-euro:before {
  content: '\e7ee';
}
.icon-gbp:before {
  content: '\e7ef';
}
.icon-dollar:before {
  content: '\e7f0';
}
.icon-rupee:before {
  content: '\e7f1';
}
.icon-yen:before {
  content: '\e7f2';
}
.icon-renminbi:before {
  content: '\e7f3';
}
.icon-won:before {
  content: '\e7f4';
}
.icon-bitcoin2:before {
  content: '\e7f5';
}
.icon-file3:before {
  content: '\e7f6';
}
.icon-file-text:before {
  content: '\e7f7';
}
.icon-sort-by-alphabet:before {
  content: '\e7f8';
}
.icon-sort-by-alphabet-alt:before {
  content: '\e7f9';
}
.icon-sort-by-attributes:before {
  content: '\e7fa';
}
.icon-sort-by-attributes-alt:before {
  content: '\e7fb';
}
.icon-sort-by-order:before {
  content: '\e7fc';
}
.icon-sort-by-order-alt:before {
  content: '\e7fd';
}
.icon-thumbs-up2:before {
  content: '\e7fe';
}
.icon-thumbs-down2:before {
  content: '\e7ff';
}
.icon-youtube-sign:before {
  content: '\e800';
}
.icon-youtube2:before {
  content: '\e801';
}
.icon-xing2:before {
  content: '\e802';
}
.icon-xing-sign:before {
  content: '\e803';
}
.icon-youtube-play:before {
  content: '\e804';
}
.icon-dropbox2:before {
  content: '\e805';
}
.icon-stackexchange:before {
  content: '\e806';
}
.icon-instagram2:before {
  content: '\e807';
}
.icon-flickr2:before {
  content: '\e808';
}
.icon-adn:before {
  content: '\e809';
}
.icon-bitbucket2:before {
  content: '\e80a';
}
.icon-bitbucket-sign:before {
  content: '\e80b';
}
.icon-tumblr2:before {
  content: '\e80c';
}
.icon-tumblr-sign:before {
  content: '\e80d';
}
.icon-long-arrow-down:before {
  content: '\e80e';
}
.icon-long-arrow-up:before {
  content: '\e80f';
}
.icon-long-arrow-left:before {
  content: '\e810';
}
.icon-long-arrow-right:before {
  content: '\e811';
}
.icon-apple:before {
  content: '\e812';
}
.icon-windows3:before {
  content: '\e813';
}
.icon-android2:before {
  content: '\e814';
}
.icon-linux:before {
  content: '\e815';
}
.icon-dribbble2:before {
  content: '\e816';
}
.icon-skype2:before {
  content: '\e817';
}
.icon-foursquare2:before {
  content: '\e818';
}
.icon-trello:before {
  content: '\e819';
}
.icon-female:before {
  content: '\e81a';
}
.icon-male:before {
  content: '\e81b';
}
.icon-gittip:before {
  content: '\e81c';
}
.icon-sun2:before {
  content: '\e81d';
}
.icon-moon:before {
  content: '\e81e';
}
.icon-archive2:before {
  content: '\e81f';
}
.icon-bug:before {
  content: '\e820';
}
.icon-renren:before {
  content: '\e821';
}
.icon-weibo2:before {
  content: '\e822';
}
.icon-vk2:before {
  content: '\e823';
}
